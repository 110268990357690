import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Steps, Input, Button, Select, Empty, Form, Typography } from 'antd';
import { trim, debounce } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSearchParam } from '../../../hooks/useSearchParam';

type Props = {
  disabled: boolean;
  stripeClientIsLoading: boolean;
  invalidCoupon: boolean;
  applyCoupon: (coupon: string) => Promise<void>;
};
export const Coupon = (props: Props) => {
  const { applyCoupon, disabled, invalidCoupon } = props;
  const [couponValue, setCoupon] = useState('');
  const [submittedValue, setSubmittedValue] = useState('');
  const defaultCoupon = useSearchParam('coupon');
  const couponInputRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(event.target.value);
  };

  const removeQueryParams = useCallback(() => {
    setSearchParams('');
    setCoupon('');
    if (couponInputRef.current) {
      couponInputRef.current.value = '';
    }
  }, [setSearchParams]);

  const handleDebounceCoupon = useMemo(() => debounce(changeHandler, 500), []);

  const isApplyVisible = useMemo(() => couponValue.length > 4, [couponValue]);

  const renderApplyButton = useCallback(() => {
    if (invalidCoupon && submittedValue === couponValue) {
      return (
        <button
          type="button"
          onClick={removeQueryParams}
          className="p-2 pr-3 rounded-tr-md rounded-br-md  hover:bg-primary-400 bg-primary-500 text-white"
        >
          Clear
        </button>
      );
    }
    if (!isApplyVisible) return null;

    return (
      <button
        type="button"
        onClick={async () => {
          await applyCoupon(couponValue);
          setSubmittedValue(couponValue);
        }}
        className="p-2 pr-3 rounded-tr-md rounded-br-md bg-primary-500 text-white hover:bg-primary-400"
      >
        Apply
      </button>
    );
  }, [
    invalidCoupon,
    submittedValue,
    couponValue,
    isApplyVisible,
    removeQueryParams,
    applyCoupon,
  ]);

  if (disabled) return null;
  return (
    <Form
      onFinish={value => {
        applyCoupon(trim(value.coupon)).catch(() => {
          applyCoupon(trim(value.coupon));
        });
      }}
      name="coupon-form"
    >
      <Form.Item
        noStyle
        rules={[
          {
            required: false,
          },
        ]}
        className="m-0 p-0 mb-0"
        name="coupon"
      >
        <div className="py-4  mb-0">
          <div className="w-full flex flex-row self-center    ">
            <input
              ref={couponInputRef}
              className={`${'flex-1 p-2 border-gray-400 border'} ${
                isApplyVisible || invalidCoupon
                  ? 'rounded-tl-md rounded-bl-md'
                  : 'rounded-md '
              }`}
              defaultValue={defaultCoupon}
              placeholder="Coupon code"
              onInput={e => {
                (e.target as HTMLInputElement).value = (
                  e.target as HTMLInputElement
                ).value.toUpperCase();
              }}
              onChange={handleDebounceCoupon}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  applyCoupon(couponValue).catch(() => {});
                }
              }}
            />
            {renderApplyButton()}
          </div>
          {invalidCoupon ? (
            <p className="text-red-500">Coupon is not valid!</p>
          ) : null}
        </div>
      </Form.Item>
    </Form>
  );
};
