import React from 'react';
import { Result, Button } from 'antd';
import { Header } from 'pages/Payment/Header';

export const SuccessPage = () => {
  return (
    <div>
      <Header />

      <div className="flex flex-1 justify-center w-full items-center self-center">
        <div className="bg-white w-3/4 items-center self-center rounded">
          <Result
            status="success"
            title="Successfully Purchased!"
            subTitle="Please check your email for further information."
            extra={[
              <Button
                onClick={() => {
                  window.location.href = 'https://getbastion.com';
                }}
                type="primary"
                key="console"
              >
                Back to Bastion
              </Button>,
            ]}
          />
        </div>
      </div>
    </div>
  );
};
