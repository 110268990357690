import { useMutation, useQuery } from 'react-query';
import { QueryNames } from './constants';
import apiCalls from './calls';
import type { GetClientSecret, PlanPrice, ProductInfoParams } from './types';
import { Package } from '../../types';
import {
  CalculatePlanPriceBody,
  GetStripeDirectPaymentSessionIdBody,
} from './types';

function removeEmpty(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export function useGetStripeClientSecret() {
  const { data, isLoading, error, mutate } = useMutation(
    (params: GetClientSecret) =>
      apiCalls.getStripeClientSecret(removeEmpty(params)),
  );
  return {
    data: data?.data?.data,
    isLoading,
    error,
    mutate,
  };
}

export function useGetProductDetails({ productId, coupon }: ProductInfoParams) {
  const { data, isLoading, error, refetch, isRefetching } = useQuery(
    QueryNames.GetStripeProduct,
    () => apiCalls.getProductDetails({ productId, coupon }),
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
}

export function useApplyCoupon() {
  const { data, isLoading, error, mutate } = useMutation(
    QueryNames.ApplyCoupon,
    ({ productId, coupon }: ProductInfoParams) =>
      apiCalls.getProductDetails({ productId, coupon }),
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error,
    applyCoupon: mutate,
  };
}

export function useGetStripDirectPaymentSessionId() {
  const { data, isLoading, error, mutate } = useMutation(
    QueryNames.GetStripeDirectPaymentSessionId,
    (params: GetStripeDirectPaymentSessionIdBody) =>
      apiCalls.getStripeDirectPaymentSessionId(params),
    {
      onError: (e: Error) => {},
      onSettled: () => {},
    },
  );
  return {
    data: data?.data?.data?.sessionId as any,
    isLoading,
    error,
    mutate,
  };
}

export function useCalculatePlanPrice() {
  const { data, isLoading, error, mutate } = useMutation<
    PlanPrice,
    Error,
    CalculatePlanPriceBody
  >(
    QueryNames.CalculatePlanPrice,
    async (params: CalculatePlanPriceBody) => {
      const response = await apiCalls.calculatePlanPrice(params);
      return response?.data?.data;
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useGetAllProducts() {
  const { data, isLoading, error } = useQuery(
    QueryNames.GetAllStripeProduct,
    () => apiCalls.getAllProducts(),
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error,
  };
}

export function useGetAllPackages() {
  const { data, isLoading, error } = useQuery<unknown, unknown, Package[]>(
    QueryNames.GetAllStripeProduct,
    async () => {
      const response = await apiCalls.getAllPackages();
      return response?.data?.data;
    },
    {
      onError: e => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data,
    isLoading,
    error,
  };
}
