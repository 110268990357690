import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Image, Space, Tag, Typography } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import { find, isEmpty } from 'lodash';
import { Coupon } from '../../../pages/Payment/components/Coupon';
import {
  CouponType,
  LineDivider,
  PackageIcon,
  Price,
} from '../../../pages/Payment/components/OrderSummary';
import { calculateDiscount } from '../../../utils/common';
import { AILMENTS, AilmentType } from '../../../services/package.static';
import { useCalculatePlanPrice } from '../../../hooks/Api/stripe';

const product = {
  id: '1',
  coupon: {
    amountOff: 1,
    percentOff: 1,
  },
  name: 'Assessment',
  description: ' Descrtiption ',
  images: [],
  price: [
    {
      unit_amount: 100,
      currency: 'ss',
    },
  ],
};

export const InvoiceCard = ({
  packageIds,
  onCouponApply,
}: {
  packageIds: string[] | undefined;
  onCouponApply: (couponCode: string | string[]) => void;
}) => {
  const isLoading = false;
  const location = useLocation();
  const { coupon: couponCode } = qs.parse(location.search);
  const previousPrice = useRef(0);
  const {
    mutate: calculatePlanAmount,
    data: planPrice,
    isLoading: isPlanPriceLoading,
  } = useCalculatePlanPrice();

  useEffect(() => {
    if (couponCode && !isEmpty(couponCode)) {
      onCouponApply(couponCode);
    }
  }, [couponCode]);

  useEffect(() => {
    if (planPrice) {
      previousPrice.current = planPrice.amount;
    }
  }, [planPrice]);

  useEffect(() => {
    if (!isEmpty(packageIds) && packageIds) {
      calculatePlanAmount({
        packageIds,
        coupon: couponCode as string,
      });
    }
  }, [packageIds, couponCode]);

  const packages = useMemo<AilmentType[] | undefined>(() => {
    return packageIds?.map(packageId => find(AILMENTS, { value: packageId }));
  }, [packageIds]);

  const renderSelectedPackages = useCallback(() => {
    return packages?.map(item => {
      return (
        <div className="flex-row flex border-gray-300 border items-center justify-items-center center mb-1 rounded p-1">
          <PackageIcon
            src={item.icon}
            width={24}
            height={24}
            className="mr-2 self-center"
          />
          <p className="text-primary-400 text-sm ">{item.name}</p>
        </div>
      );
    });
  }, [packages]);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const setQueryParams = useCallback(
    (event: { [key: string]: string }) => {
      // history.push({ pathname: location.pathname, search: params.toString() });
      setSearchParams(event, { replace: true });
    },
    [setSearchParams],
  );

  const removeQueryParams = useCallback(() => {
    setSearchParams('');
  }, [setSearchParams]);

  const { coupon } = product;
  const [discount, setDiscount] = useState(0);

  const price = useMemo(() => {
    return planPrice?.amount || previousPrice?.current || 0;
  }, [planPrice, previousPrice]);
  const invalidCoupon = useMemo(
    () => !planPrice?.isCouponValid && !isEmpty(couponCode),
    [planPrice, isLoading, couponCode],
  );

  useEffect(() => {
    setDiscount(planPrice?.discount || 0);
  }, [planPrice]);

  const subTotal = useMemo(() => {
    return (price - discount).toFixed(2);
  }, [discount, price]);

  const renderDiscount = useCallback(() => {
    if (isPlanPriceLoading) return null;
    if (discount) {
      return (
        <div className="flex flex-row justify-between pb-3 pt-3">
          <Space direction="horizontal">
            <p className="font-bold text-primary-500">Discount</p>
            <Tag
              className="cursor-pointer"
              onClick={() => {
                removeQueryParams();
              }}
            >
              {' '}
              Remove{' '}
            </Tag>
          </Space>
          <p className="font-bold text-primary-500">{`- $${discount}`}</p>
        </div>
      );
    }

    return (
      <Coupon
        disabled={false}
        stripeClientIsLoading={false}
        invalidCoupon={invalidCoupon}
        applyCoupon={async value => {
          setQueryParams({
            coupon: value,
          });
          onCouponApply(value);
        }}
      />
    );
  }, [
    discount,
    removeQueryParams,
    setQueryParams,
    invalidCoupon,
    isPlanPriceLoading,
  ]);

  const renderPrice = useCallback(() => {
    return (
      <p className="text-primary-700 font-bold text-3xl mb-2">
        US$ {price.toFixed(2)}
      </p>
    );
  }, [price]);

  const renderBastionLogo = useCallback(() => {
    return (
      <div className="">
        <div
          onClick={() => {
            window.location.href = 'https://getbastion.com';
          }}
          className="flex flex-row align-middle cursor-pointer "
        >
          <Image
            preview={false}
            width={28}
            className="rounded-full"
            src="https://images.typeform.com/images/EUWruLvdHE8P/choice/thumbnail"
          />
          <Typography.Text
            className="text-primary-700"
            style={{
              alignSelf: 'center',
              paddingLeft: 8,
            }}
          >
            Bastion Health
          </Typography.Text>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
      <div className="mb-8">
        <div className="text-center md:text-left">
          {renderBastionLogo()}
          <p className="text-gray-400 font-bold text-sm mb-1">
            Pay Bastion Health
          </p>
          {renderPrice()}
        </div>
        <div className="flex flex-1 flex-col md:flex-row pt-3 justify-center ">
          <div className="w-full flex-col">
            <div className="w-full flex flex-row">
              <p className="text-primary-500">{product.name}</p>
              <div className="flex flex-1 justify-end">
                <p className="text-primary-500 ">{`$${price.toFixed(2)}`}</p>
              </div>
            </div>
            <div className="pb-10 ">{renderSelectedPackages()}</div>

            <div className="flex flex-row justify-between pb-3">
              <p className="font-bold text-primary-500">Subtotal</p>
              <p className="font-bold text-primary-500">{`US$${price.toFixed(
                2,
              )}`}</p>
            </div>
            <LineDivider />
            <div>{renderDiscount()}</div>
            <LineDivider />
            <div className="flex flex-row justify-between pt-3 ">
              <div className="back ">Total due</div>
              <p className="font-bold text-primary-500">{`US$${subTotal}`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
