import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Image, Popover, Typography } from 'antd';
import { css } from '@emotion/react';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { find, isEmpty, uniq } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  AILMENTS,
  AilmentType,
  ORGANIZATIONS_PACKAGES_MAP,
  PackageUniqueCode,
} from '../../../services/package.static';

type Props = {
  onSelect: (value: string[]) => void;
  defaultValue?: string[];
};

type ParamsProps = {
  employerCode: string;
};

export const TwoStepPackageSelection = ({
  onSelect,
  defaultValue = [],
}: Props) => {
  const { employerCode = '' } = useParams<ParamsProps>();
  const [selected, setSelected] = useState<string[]>(defaultValue);

  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  const renderComingSoonBadge = useCallback(() => {
    return (
      <div className="absolute bottom-2 right-2 bg-primary-gold-700 text-white text-xs font-bold px-2 py-1 rounded z-10 ">
        Coming Soon
      </div>
    );
  }, []);

  const renderProductCard = useCallback(
    ailment => {
      const isSelected = selected.includes(ailment.value);
      if (ailment?.disabled) return null;
      return (
        <div
          className="flex flex-1 w-full relative"
          onClick={() => {
            if (ailment?.comingSoon) {
              return;
            }
            setSelected(pv => {
              //  Remove if already selected and add if not available
              if (pv.includes(ailment.value)) {
                return pv.filter(item => item !== ailment.value);
              }
              return pv.concat(ailment.value);
            });
          }}
        >
          {ailment.comingSoon && renderComingSoonBadge()}
          <div
            className={`
          bg-white rounded-lg border border-gray-200  dark:bg-gray-800 dark:border-gray-700 flex flex-1 flex-col
          shadow-md
          ${
            isSelected
              ? 'border-primary-600 border-double- shadow-md bg-primary-500'
              : ''
          }
          ${
            ailment?.comingSoon
              ? 'cursor-not-allowed opacity-50'
              : 'cursor-pointer hover:border-2 hover:border-primary-600 hover:shadow-md'
          }
          `}
            css={css`
              width: 100%;
              /*max-width: 250px;*/
            `}
          >
            <div className="p-3 flex flex-1 flex-col ">
              <div className="flex justify-between flex-row flex-1 items-start">
                <div className="flex flex-row space-x-2">
                  <Image
                    src={isSelected ? ailment.selectedIcon : ailment.icon}
                    preview={false}
                    width={40}
                    height={40}
                    style={{ objectFit: 'contain' }}
                  />
                  <div>
                    <p
                      className={`
                      text-l font-bold tracking-tight
                      ${
                        isSelected
                          ? 'text-white'
                          : 'text-gray-900 dark:text-white'
                      }
                      `}
                    >
                      {ailment.name}
                    </p>
                    <p
                      css={css`
                        color: ${isSelected ? 'white' : '#aaa7a7'};
                        font-family: 'Roboto';
                        font-size: 11px;
                        margin-bottom: 10px;
                      `}
                    >
                      {ailment.subName || <br />}
                    </p>
                  </div>
                </div>
                <Popover
                  content={ailment.description}
                  title={ailment.name}
                  trigger="hover"
                  overlayClassName="max-w-xs"
                >
                  <InfoCircleOutlined
                    color={'white'}
                    style={{ color: isSelected ? 'white' : '#aaa7a7' }}
                    onClick={() => {}}
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [setSelected, selected],
  );

  const renderDisclaimer = useCallback(() => {
    const selectedPackages = AILMENTS.filter(item =>
      selected.includes(item.value),
    );
    const relatedPackages = uniq(
      selectedPackages.reduce((acc: PackageUniqueCode[], item) => {
        return acc.concat(item.relatedPackages);
      }, []),
    );

    /* Find those packages that are in relatedPackages but not in selected packages */
    const relatedPackagesNotSelected = relatedPackages.filter(
      item =>
        !selectedPackages
          .map(selectedPackage => selectedPackage.value)
          .includes(item),
    ) as PackageUniqueCode[];
    const complimentaryPackages = AILMENTS.filter(item =>
      relatedPackagesNotSelected.includes(item.value),
    );
    if (complimentaryPackages.length === 0) return null;
    const complimentaryPackagesNames = complimentaryPackages.reduce(
      (acc, item, currentIndex) => {
        /* If it's one to last add "and" */
        if (currentIndex === complimentaryPackages.length - 2) {
          return `${acc}${item.name} and `;
        }
        /* If it's last add nothing */
        if (currentIndex === complimentaryPackages.length - 1) {
          return `${acc}${item.name}`;
        }
        /* If it's not last add comma */
        return `${acc}${item.name}, `;
      },
      '',
    );

    return (
      <div
        css={css`
          font-family: 'Roboto';
        `}
        className="pt-5"
      >
        *Note: To comprehensively assess your health your selected package also
        assess you for {complimentaryPackagesNames}.
      </div>
    );
  }, [selected]);

  const packagesData = useMemo(() => {
    const employerCoveredPackageIds = find(ORGANIZATIONS_PACKAGES_MAP, {
      employerCode,
    })?.supportedPackages?.map(item => item.packageId);
    /**
     * if there are no information about the covered package for a specific organization we show all packages as covered
     *  Otherwise we split based on covered packages
     * */
    const coveredPackages: AilmentType[] = [];
    const noneCoveredPackages: AilmentType[] = [];

    if (isEmpty(employerCoveredPackageIds)) {
      coveredPackages.push(...AILMENTS);
    } else {
      AILMENTS.forEach(item => {
        if (employerCoveredPackageIds?.includes(item.value)) {
          coveredPackages.push(item);
        } else {
          noneCoveredPackages.push(item);
        }
      });
    }
    return { coveredPackages, noneCoveredPackages };
  }, [employerCode]);

  const renderPackages = useCallback(() => {
    if (isEmpty(packagesData.noneCoveredPackages)) {
      return (
        <div className="grid gap-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 mb-10">
          {packagesData.coveredPackages.map(item => renderProductCard(item))}
        </div>
      );
    }

    return (
      <div>
        <p className="text-lg text-gray-600 font-roboto mb-3">
          In Network Package provided by your employee health benefits
          <p className="text-xs text-gray-600 font-roboto ">
            (Cost Sharing May Apply)
          </p>
        </p>
        <div className="grid gap-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 mb-10">
          {packagesData.coveredPackages.map(item => renderProductCard(item))}
        </div>
        <Divider />

        <p className="text-lg text-gray-600 font-roboto mb-3 ">
          Out of Network Packages by Bastion Health
          <p className="text-xs text-gray-600 font-roboto ">
            (Select 1 or more additional packages for flat fee of $400)
          </p>
          <p className="text-xs text-gray-500 font-roboto ">
            *Services below are in-network only for UnitedHealthcare members.
          </p>
        </p>

        <div className="grid gap-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
          {packagesData.noneCoveredPackages.map(item =>
            renderProductCard(item),
          )}
        </div>
      </div>
    );
  }, [packagesData, renderProductCard]);

  return (
    <div>
      <div>{renderPackages()}</div>
      {renderDisclaimer()}
    </div>
  );
};
